<template>
  <table class="listado table table-hover m-0 tabla-desktop">
    <thead class="bg-primary text-white">
      <th>{{ $t("Nombre") }}</th>
      <th>{{ $t("Estado") }}</th>
      <th>{{ $t("Por defecto") }}</th>
      <th class="th-acciones">{{ $t("Acciones") }}</th>
    </thead>
    <tbody>
      <tr v-for="cuadroMando in cuadrosMando" v-bind:key="cuadroMando.id">
        <td>{{ cuadroMando.nombre }}</td>
        <td>{{ cuadroMando.activo | activo }}</td>
        <td>{{ cuadroMando.principal | sino }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <botonera-acciones-tabla :cuadroMando="cuadroMando" @changed="onChanged" />
        </td>
      </tr>
      <sin-registros :count="cuadrosMando.length" :colspan="6"></sin-registros>
    </tbody>
  </table>
</template>
<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";
export default {
  props: {
    cuadrosMando: Array,
  },
  methods: {
    onChanged(id) {
      this.$emit("changed", id);
    },
  },
  components: {
    BotoneraAccionesTabla,
  },
};
</script>
