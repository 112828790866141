<template>
  <div class="listado tabla-mobile">
    <filtros-listado-mobile>
      <filtro-activo field="activo" />
    </filtros-listado-mobile>
    <fila-compacta v-for="(cuadroMando, idx) in cuadrosMando" v-bind:key="idx" :titulo="cuadroMando.nombre">
      <div>
        {{ $t("Estado") }}:
        <span v-if="cuadroMando.activo == true">Activo</span>
        <span v-else>Inactivo</span>
      </div>

      <botonera-acciones-tabla slot="toolbar" :cuadroMando="cuadroMando" @changed="onChanged" />
    </fila-compacta>

    <h3 class="text-center" v-if="cuadrosMando.length == 0">
      {{ $t("No hay cuadros de mando") }}
    </h3>
  </div>
</template>

<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";

export default {
  props: {
    cuadrosMando: { type: Array, required: true },
  },
  data() {
    return {
      activo: 1,
    };
  },
  components: { BotoneraAccionesTabla },
  methods: {
    onChanged(id) {
      this.$emit("changed", id);
    },
  },
};
</script>
