<template>
  <div>
    <tabla-mobile :cuadrosMando="cuadrosMando" @changed="onChanged" />
    <tabla-desktop :cuadrosMando="cuadrosMando" @changed="onChanged" />
  </div>
</template>
<script>
import TablaMobile from "./TablaMobile.vue";
import TablaDesktop from "./TablaDesktop.vue";
export default {
  components: {
    TablaMobile,
    TablaDesktop,
  },
  props: {
    cuadrosMando: Array,
  },
  methods: {
    onChanged(id) {
      this.$emit("changed", id);
    },
  },
};
</script>
