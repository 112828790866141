<template>
  <acciones>
    <accion-primaria
      :label="$t('Editar')"
      icon="edit"
      :to="`/administracion/cuadros-de-mando/editar/${cuadroMando.id}`"
    />

    <acciones-secundarias>
      <accion-secundaria
        :label="$t('Diseñar')"
        icon="edit-3"
        :to="`/administracion/cuadros-de-mando/asignar-indicadores/${cuadroMando.id}`"
      ></accion-secundaria>
      <accion-secundaria
        v-if="cuadroMando.activo"
        @click="predeterminar(cuadroMando.id)"
        :label="$t('Marcar por defecto')"
        icon="check"
      ></accion-secundaria>
      <accion-eliminar
        @eliminar="eliminarCuadroMando(cuadroMando)"
        :confirmacion="`¿Desea eliminar el cuadro de mando ${cuadroMando.nombre}?`"
      />
    </acciones-secundarias>
  </acciones>
</template>
<script>
import * as apiCuadrosMando from "@/services/cuadrosMando.js";
export default {
  props: {
    cuadroMando: Object,
  },
  methods: {
    async eliminarCuadroMando(cuadroMando) {
      await apiCuadrosMando.eliminar(cuadroMando.id);
      this.$root.$emit("userMessage", this.$t("Cuadro de mando eliminado"));
      this.$emit("changed", cuadroMando.id);
    },
    async predeterminar(id) {
      await apiCuadrosMando.predeterminar(id);
      this.$emit("changed", id);
    },
  },
};
</script>
